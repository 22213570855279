import 'font-awesome/css/font-awesome.css'

import 'intl-tel-input/build/css/intlTelInput.css'

import '../fonts/lineicons3/icon-font/scss/lineicons.scss'

import '../css/sb-admin-2.css'

import '../scss/admin.scss'

let $ = window.jQuery = window.$ = require('jquery')
require('popper.js')
require('bootstrap')

require('./sb-admin/jquery-easing/jquery.easing')

setTimeout(() => {
  require('./sb-admin/sb-admin-2')
}, 2000)

$(function () {
  $('[data-toggle="tooltip"]').tooltip()
})